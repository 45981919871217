import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productAPI, categoryAPI } from "../../api/api";

export const getSearchProducts = createAsyncThunk(
    "search/getSearchProducts",
    async (params, thunkAPI) => {
        const {value, offset = 0, limit = 5} = params;
        try {
            const res = await productAPI.search(`search/query=${value}`, offset, limit);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const getSearchCategories = createAsyncThunk(
    "search/getSearchCategories",
    async (name, thunkAPI) => {
        try {
            const res = await categoryAPI.search(name);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const searchSlice = createSlice({
    name: "search",
    initialState: {
        products: [],
        categories: [],
        count: 0,
        pageSize: 25, 
        isLoadingSearchProducts: false,
    },
    reducers: {
        clearSearchProducts: (state) => {
            state.products = []
            state.categories = [];
            state.count = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchProducts.pending, (state) => {
            state.isLoadingSearchProducts = true;
        });
        builder.addCase(getSearchProducts.fulfilled, (state, { payload }) => {
            state.products = payload.products;
            state.count = payload.count;
            state.isLoadingSearchProducts = false;
        });
        builder.addCase(getSearchProducts.rejected, (state) => {
            state.isLoadingSearchProducts = false;
        });
        builder.addCase(getSearchCategories.fulfilled, (state, { payload }) => {
            state.categories = payload;
        }); 
    },
});
  
export default searchSlice.reducer;
export const {
    clearSearchProducts
} = searchSlice.actions;