import React from 'react'
import styles from '../../styles/Promo.module.css'
import { useSelector } from 'react-redux';

const Promo = () => {
    const { settings } = useSelector(({ settings }) => settings);
    // console.log(settings)
    return (
        <section className={styles.promo}>
            <div className='container'>
                <p>Добро пожаловать в интерент-магазин</p>
                <h1 className={styles.title}>{settings.name}</h1>
                {settings.phone && <p>Тел: {settings.phone}</p>}
            </div>
        </section> 
    )
}

export default Promo