import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FiSearch, FiExternalLink, FiX, FiArrowLeft }  from "react-icons/fi";
import style from './PreSearch.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getPreSearchProducts, getPreSearchCategories, clearSearchProducts } from '../../../features/search/preSearchSlice'
import PreSearchItem from './PreSearchItem';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes'

const PreSearch = () => {
    const { products, isLoadingSearchProducts, count, categories } = useSelector(({ presearch }) => presearch);
    const [isOpenMobile, setIsOpenMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchRef = useRef(null);

    useEffect(() => {
        if (searchValue.length <= 1) {
            dispatch(clearSearchProducts())
        } else {
            dispatch(getPreSearchProducts(searchValue))
            dispatch(getPreSearchCategories(searchValue))
        }       
    }, [dispatch, searchValue]);

    function handleSearchChange(event) {
        setSearchValue(event.target.value )   
    }

    // history storage
    const [searchHistory, setSearchHistory] = useState([]);
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('search-history'));
        if (items) {
            setSearchHistory(items);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('search-history', JSON.stringify(searchHistory));
    }, [searchHistory]);

    function setItemToLocalStorage(value) { 
        const in_history = searchHistory.find((item) => item.name === value);
        if (in_history) return
        if (value.length <= 1) return    
        setSearchHistory([ ...searchHistory,  {'name': value, 'url': `/search/?query=${value}` }])  
    }
    function removeItemToLocalStorage(value) { 
        const filter_history = searchHistory.filter((item) => item.name !== value);
        setSearchHistory(filter_history)
    }

    const handleSubmit = (event) => { 
        event.preventDefault();
        if (searchValue.length === 0) return searchRef.current.focus();
        
        setItemToLocalStorage(searchValue)
        navigate(`${ROUTES.SEARCH}?query=${searchValue}`);
        setIsOpen(false)
    }

    return <>
        <div className={`${style.mobile_icon_wrap} hide-on-med-and-up`}>
            <FiSearch className={style.mobile_icon} 
                onClick={() => setIsOpenMobile(!isOpenMobile)} />
        </div>
         
        <div className={`${style.search}${isOpenMobile ? ` ${style.search_open}` : ``}`}>
            
            <div className={style.wrap}>
                <form className={style.form}
                    action={'/search/'}
                    onSubmit={handleSubmit} 
                >
                    <FiArrowLeft className='hide-on-med-and-up' onClick={() => setIsOpenMobile(!isOpenMobile)} />  
                    <div className={style.input} onClick={()=> setIsOpen(true)}>
                        <input 
                            onChange={handleSearchChange}
                            type="search" 
                            name="query" 
                            placeholder="Поиск товаров..." 
                            autoComplete="off"
                            value={searchValue}
                            ref={searchRef}
                        />  
                    </div>
                    {searchValue.length > 0 && 
                    <button type='reset' onClick={() => setSearchValue('')}><FiX /></button>}
                    <button type='submit'><FiSearch /></button>
                </form>

                {isOpen &&
                <div className={style.suggests}>

                    {( searchValue.length <= 1 && searchHistory.length > 0 ) && <>
                        <div className={style.suggests_title}>История поиска</div>
                        {searchHistory.map(({name, url}, i) => 
                            <div key={i} className={style.suggests_item}>
                                <Link to={url} onClick={()=> setIsOpen(false)} className={style.suggests_item_name}>{name}</Link> 
                                <FiX onClick={() => removeItemToLocalStorage(name)} />
                            </div>
                        )}
                    </> }

                    {isLoadingSearchProducts 
                    ? <div className={style.suggests_item}>...loading</div> 
                    : count > 0 && <>
                        <div className={style.suggests_title}>Найдено {count} товаров</div>
                        {products.map(({ name, id }, i) =>
                        <Fragment key={i}>
                            <PreSearchItem 
                                FiExternalLink={FiExternalLink}
                                style={style} 
                                name={name}
                                url={`/product/${id}`}
                                searchValue={searchValue}
                                setIsOpen={setIsOpen} 
                            />
                        </Fragment>
                        )}
                        </>
                    }
                    
                    {categories.length > 0 && <>
                        <div className={style.suggests_title}>Найдено {categories.length} категории</div>
                        {categories.slice(0, 5).map(({ name, url }, i) =>
                        <Fragment key={i}>
                            <PreSearchItem 
                                FiExternalLink={FiExternalLink}
                                style={style} 
                                name={name}
                                url={`/category/${url}`}
                                searchValue={searchValue}
                                setIsOpen={setIsOpen} 
                            />
                        </Fragment>
                    )}</>}

                    {/* {searchValue.length > 1 && 
                    <div className={style.suggests_item}>
                        найдено {count} товаров, {categories.length} категории
                    </div>
                    } */}
                </div>
                }
            </div>
            {isOpen && <div className={style.overlay} onClick={()=> setIsOpen(false)}></div>} 
        </div>  
    </>
}

export default PreSearch