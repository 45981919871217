import React from 'react'
import styles from "../../styles/Products.module.css";
import ProductsItem from './ProductsItem' 
import { useSelector } from 'react-redux'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const Products = ({products, column = 'col s6 m4 l3'}) => {

    const { cart } = useSelector(({ cart }) => cart);

    return <>
        <div className='row flex wrap'>
            {products && products.map((item, index) => (
                <div key={index} className={column}>
                    <div className={styles.product}>
                        <ProductsItem 
                            item={item} 
                            cart={cart} 
                            // toast={toast} 
                        />
                    </div>
                </div>
            ))}
        </div>
        {/* <ToastContainer theme="light"/> */}
        </>
}

export default Products