import { configureStore } from "@reduxjs/toolkit"
import categoriesSlice from "./categories/categoriesSlice"
import productsSlice from "./products/productsSlice"
import productSlice from "./product/productSlice"
import featureSlice from "./feature/featureSlice"
import settingsSlice from "./settings/settingsSlice"
import preSearchSlice from "./search/preSearchSlice"
import searchSlice from "./search/searchSlice"
import { cartReducer } from "./cart/cartSlice"
import customerSlice from "./customer/customerSlice"
import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'


const persistConfig = {
    key: 'root',
    storage,
}
const persistedCartReducer = persistReducer(persistConfig, cartReducer)
const persistedCustomerReducer = persistReducer(persistConfig, customerSlice)

export const store = configureStore({
    reducer: {
        categories: categoriesSlice,
        products: productsSlice,
        product: productSlice,
        features: featureSlice,
        settings: settingsSlice,
        cart: persistedCartReducer,
        customer: persistedCustomerReducer,
        search: searchSlice,
        presearch: preSearchSlice
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store)