import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderAPI, productAPI } from "../../api/api";

export const addOrder = createAsyncThunk(
    "cart/addOrder",
    async (data, thunkAPI) => {       
        try {
            const res = await orderAPI.add(data);
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
); 

export const getOrderItemsAvailability = createAsyncThunk(
    "cart/getOrderItemsAvailability",
    async (ids, thunkAPI) => {
        try {
            const res = await productAPI.search(`search/sku_id=${ids}`, 0, 200, 'skus_filtered');
            return res;
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        // cartAvailability: {},
        isCheckedControls: true,
        success: '',
    },
    reducers: {
        addToCart: (state, action) => {
            const itemInCart = state.cart.find((item) => item.id === action.payload.id);
            if (itemInCart) {
                itemInCart.quantity++;
            } else {
                // push добавляет в конец массива, unshift добавляет в начало
                state.cart.unshift({ ...action.payload, quantity: 1, checked: true, disabled: false });
            }
        },
        incrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
        },
        checkedItem: (state, action) => {           
            const item = state.cart.find((item) => item.id === action.payload);
            item.checked === true ? item.checked = false : item.checked = true

            const countChecked = state.cart.filter(item => item.checked === true).length;
            countChecked === state.cart.length 
            ? state.isCheckedControls = true 
            : state.isCheckedControls = false
        },
        checkedAllItems: (state, {payload}) => {
            const updatedItems = state.cart.map((item) =>                
                item.checked !== payload ? { ...item, checked: !item.checked } : item  
            )  
            state.isCheckedControls = payload
            state.cart = updatedItems
        },
        removeItem: (state, action) => {
            const removeItem = state.cart.filter((item) => item.id !== action.payload);
            state.cart = removeItem;
        },
        removeCart: (state) => { 
            const removeCart = [];
            state.cart = removeCart;
        },
        removeItems: (state) => { 
            const filteredCart = state.cart.filter((item) => item.checked === false);
            state.cart = filteredCart;
        }    
    },
    extraReducers: (builder) => {
        builder.addCase(addOrder.fulfilled, (state, { payload }) => {
            const filteredCart = state.cart.filter((item) => item.checked === 0);
            state.cart = filteredCart;
            state.success = payload;
        });
        builder.addCase(getOrderItemsAvailability.fulfilled, (state, { payload }) => {
            const Skus = {}
            payload.products.map(({ skus, name }) => 
                Object.values(skus).map( (item) => Skus[item.id] = {...item, name } )
            )
            const updatedCart = state.cart.map((item) =>                
                (Skus[item.id] && item.name ===  Skus[item.id]?.name) ? {
                    ...item,
                    count: Skus[item.id].count, 
                    disabled: (
                        parseInt(Skus[item.id].count) === 0 
                        || Skus[item.id].available === '0' 
                        || Skus[item.id].status === '0'
                    ) 
                } : { ...item, disabled: true }
            ) 
            // state.cartAvailability = Skus;
            state.cart = updatedCart;  
        });
    },
});

export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    removeCart,
    checkedItem,
    removeItems,
    checkedAllItems,
} = cartSlice.actions;