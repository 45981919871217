import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { categoryAPI } from "../../api/api";

export const getCategories = createAsyncThunk(
    "categories/getCategories",
    async (params, thunkAPI) => {
        const {parent_id, depth} = params;
        try {
            const res = await categoryAPI.getTree(parent_id, depth);           
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const getPriceRange = createAsyncThunk(
    "categories/getPriceRange",
    async (id, thunkAPI) => {
        try {
            const res = await categoryAPI.getPriceRange(id);           
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);  

const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        categories: [],
        isLoading: false,
        price_range: { 
            min: 0, 
            max: 0 
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCategories.fulfilled, (state, { payload }) => {
            state.categories = payload;
            state.isLoading = false;
        });
        builder.addCase(getCategories.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getPriceRange.fulfilled, (state, { payload }) => {
            state.price_range = payload;
        });
    },
});
  
export default categoriesSlice.reducer;