import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { featureAPI } from "../../api/api";

export const getFeatures = createAsyncThunk(
    "feature/getFeatures",
    async (type_id, thunkAPI) => {
        try {
            const res = await featureAPI.getList(type_id);
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const getInitialFilterItems = (features, categoryFiltersArr) => {
    function decToHex(n){ 
        let hex = '000000' + Number(n).toString(16)
        hex = '#' + hex.slice(-6)
        return hex; 
    }

    // поля фильтра
    const initial = [];
    const featuresWidthPrice = Object.values({...features})
        featuresWidthPrice.unshift({ id: 'price', code: 'price', name: 'Цена', builtin: '0 ', type: 'range.double'})
    const items_filter = featuresWidthPrice
        .filter(item => categoryFiltersArr.includes(item.id))
        .filter(item => item.builtin == 0)

    // инициализирую начальные значения фильтра
    items_filter.map(({ code, type, values }) => {
        if (code === 'price') {
            if (!initial.find(item => item.code === 'price')) {
                initial.push(
                    { code, name: 'filters[price_min]', range: 'min', value: '', type: 'number' },
                    { code, name: 'filters[price_max]', range: 'max', value: '', type: 'number' },
                    { code, name: 'filters[price]', value: 0, type: 'range' }
                )
            }
        } else if (type == 'boolean') {
            initial.push(
                { id: `${code}-1`, code, name: `filters[${code}]`, label: 'Да', value: '1', type: 'radio', 'isChecked': false },
                { id: `${code}-2`, code, name: `filters[${code}]`, label: 'Нет', value: '0', type: 'radio', 'isChecked': false },
                { id: `${code}-3`, code, name: `filters[${code}]`, label: 'Не важно', value: '', type: 'radio', 'isChecked': true }
            ) 
        } else if (type === 'range.double') {              
            initial.push(
                { code, name: `filters[${code}][min]`, range: 'min', value: '', type: 'number' },
                { code, name: `filters[${code}][max]`, range: 'max', value: '', type: 'number' }
            )      
        } else if (type === 'color' || type === 'varchar') {
            values.map((p, i) => { 
                initial.push({
                    code,
                    name: `filters[${code}][${i}]`,
                    label: p.value, 
                    value: p.id,
                    type: 'checkbox',
                    'isChecked': false,
                    color: p.code ? decToHex(p.code) : false   
                })     
            })
        }
    })
    const initialFilterItems = [...initial]
    return {items_filter, initialFilterItems}  
}

const featuresSlice = createSlice({
    name: "feature",
    initialState: {
        features: [],
        isLoadingFeatures: false,
        initialFilter: [],
    },
    reducers: {
        getCategoryFilters: (state, { payload }) => {
            const categoryFiltersArr = payload ? payload.split(',') : []
            state.initialFilter = getInitialFilterItems(state.features, categoryFiltersArr)
            // state.initialFilter = payload;
        },
        selectedIitemFilter: (state, { payload }) => { 
            const initialFilterItems = state.initialFilter.initialFilterItems         
            if (payload.type === 'checkbox') {
                const item = initialFilterItems
                .find((item) => item.name === payload.name);
               item.isChecked = payload.checked 
            }
            if (payload.type === 'radio') {
                const items = initialFilterItems
                    .filter((item) => item.name === payload.name);
                const itemCurrent = initialFilterItems
                    .find( (item) => item.id === payload.id );
                items.map( (item) =>  item.isChecked = false )
                itemCurrent.isChecked = payload.checked
            }    
            if (payload.type === 'number') {
                const item = initialFilterItems
                .find((item) => item.name === payload.name);
                item.value = payload.value
            } 
            
        },
        resetSelectedItemFilter: (state) => {
            const updatedItems = state.initialFilter.initialFilterItems
            .map((item) => 
                item.isChecked === true ? { ...item, isChecked: false } : 
                item.type === 'number' ? { ...item, value: '' } : item
            )
            state.initialFilter.initialFilterItems = updatedItems;           
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFeatures.pending, (state) => {
            state.isLoadingFeatures = true;
        });
        builder.addCase(getFeatures.fulfilled, (state, { payload }) => {
            state.features = payload;
            state.isLoadingFeatures = false;
        });
        builder.addCase(getFeatures.rejected, (state) => {
            state.isLoadingFeatures = false;
        });
    },
});
  


export default featuresSlice.reducer;
export const {
    getCategoryFilters,
    selectedIitemFilter,
    resetSelectedItemFilter,
} = featuresSlice.actions;