import React, { useEffect } from 'react';
import Header from '../Header/Header';
import AppRoutes from '../Routes/Routes';
import { useDispatch } from 'react-redux';
import { getCategories } from '../../features/categories/categoriesSlice';
import Footer from '../Footer/Footer';
import { getFeatures } from '../../features/feature/featureSlice';
import { getSettings } from '../../features/settings/settingsSlice';
// import { getProducts } from '../../features/products/productsSlice';

const App = () => {
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFeatures()); 
        dispatch(getCategories( { parent_id: 0, depth: 6 } ));
        dispatch(getSettings());
        //dispatch(getProducts('category/2', 0, 9, ''));
        
    }, [dispatch]);

    return (        
        <div className="app">
            <Header />
            <div className='main'>
                <AppRoutes />
            </div>
            <Footer />          
        </div>
    );
}

export default App;
