import React, { useEffect, useState } from 'react'
import styles from "../../styles/Product.module.css";
import Price from '../common/Price/Price';
import { useDispatch, useSelector } from 'react-redux';
import {addToCart} from '../../features/cart/cartSlice';
import * as Feather from "react-icons/fi";
import { DOMAIN } from '../../utils/constants';
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';

function replaceImageSize(url) { 
    if (typeof url === 'object') url = url.toString();   
    return typeof url === 'string' ? url
        .replace('200x0', '750x0').replace('@2x', '') : '';
}
const ProductCart = ({
        id, name, summary, images = [], image_url, status, available,
        price, compare_price,currency, sku_type, skus = [], sku_id, count 
    }) => {
    
    const dispatch = useDispatch();   
    const [currentImage, setCurrentImage] = useState(replaceImageSize(image_url));
    const [skuId, setSkuId] = useState(sku_id);
    const [sku, setSku] = useState([]);
    const [productPrice, setPrice] = useState(price);
    const [productComparePrice, setComparePrice] = useState(compare_price);
    const [skuImageUrl, setSkuImageUrl] = useState(new URL(image_url, DOMAIN));
    const [productAvailable, setProductAvailable] = useState(available);
    const [productCount, setProductCount] = useState(count);
    const [productStatus, setProductStatus] = useState(status);

    const [swiper, setSwiper] = useState();    

    // получаю основное изображение
    // useEffect(() => {
    //     if (!image_url) return
    //     setCurrentImage(image_url);
    // }, [image_url]);

    // меняю данные в зависимости от id товарной позиции (sku)
    useEffect(() => {
        setSku(skus.find(s => s.id === skuId))
        if (!sku) return
        setPrice(sku.price)
        setComparePrice(sku.compare_price)
        setProductAvailable(sku.available)
        setProductCount(sku.count)
        setProductStatus(sku.status)
    }, [sku, skus, skuId ]);

    // получаю изображение товарной позиции в зависимости от sku
    useEffect(() => {
        if (!images) return
        const skuImage = images.find(s => s.id === sku.image_id)

        const activeIndex = images.findIndex(s => s.id === sku.image_id)
        if (activeIndex >= 0) swiper.slideTo(activeIndex)

        if (!skuImage) return
        setSkuImageUrl( new URL(skuImage.url_thumb, DOMAIN) );
        setCurrentImage( replaceImageSize(new URL(skuImage.url_thumb, DOMAIN)) );
    }, [sku, sku.image_id]);

    // select выбор товарной позиции sku
    function changeSelectSku(event) {
        setSkuId(event.target.value);     
    }

    // клик по превью миниатюре
    function onClickImagePreview(index, image) {
        setCurrentImage(replaceImageSize(new URL(image, DOMAIN)))
        // swiper.slideTo(index)   
    }

    // проверяю на наличие в корзине
    const { cart } = useSelector(({ cart }) => cart);
    const [inbasket, setInbasket] = useState(false);
    useEffect(() => {       
        const cartItem = cart?.find((item) => item.id === skuId)
        if (cartItem?.id === skuId) {
            setInbasket(true);
        } else {
            setInbasket(false);
        }          
    }, [cart, skuId]);

    

    return (      
        <div className={`row flex wrap ${styles.product}`}>
            <div className='col s12 m6'>
                <div className={styles.images}>
                    <div className={styles.current}
                        style={{ backgroundImage: `url(${currentImage ? currentImage : '/images/dummy200.png' })` }}
                    />
                    <div 
                        className={styles.images_slider_preveiw}
                        // className={styles.images_list}
                    >
                        <Swiper
                            onSwiper={(swiper) => setSwiper(swiper)} 
                            initialSlide={0}
                            slidesPerView={4}
                            spaceBetween={5}
                            mousewheel={true}
                            direction="vertical"
                        >
                            {images && 
                                images.slice(0, 10).map((image, i) => ( 
                                <SwiperSlide key={i}>   
                                    <div className={`${styles.image} ${
                                            currentImage === replaceImageSize(new URL(image.url_thumb, DOMAIN)) 
                                            ? styles.active : '' }`}
                                        style={{ backgroundImage: `url(${new URL(image.url_thumb, DOMAIN)})` }}
                                        onMouseEnter={() => onClickImagePreview( i, image.url_thumb )}
                                    />
                                </SwiperSlide> 
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
            
            <div className='col s12 m6'>                     
                <form method="dialog" className={styles.info}>
                    <h1 className={styles.title}>{name}</h1> 
                    <p className={styles.description}>{summary}</p>

                    {sku_type === '1' && (
                        <label className={styles.options}>
                            <select name="productOptions" value={skuId} onChange={changeSelectSku}>
                            {skus && skus.map((sku) => (
                                sku.status === '1' &&
                                <option key={sku.id} value={sku.id}>
                                    {sku.name}  
                                </option> 
                            ))}    
                            </select>  
                        </label> 
                    )}

                    <p className={styles.description}>
                        Статус (Видимость на витрине): {productStatus}<br/>
                        Доступен для заказа: {productAvailable}<br/>
                        Выбрана опция (SKU): {skuId}<br/>
                        На складе: {productCount}
                    </p>
                    
                    {(productAvailable === '0' || productStatus === '0') 
                        ? <span className={styles.stock_none}>Недоступен для покупки</span>
                        : parseInt(productCount) === 0
                        ? <span className={styles.stock_none}>Закончился на складе</span>
                        : parseInt(productCount) <= 4
                        ? <span className={styles.stock_high}>Осталось несколько штук</span>
                        : <span className={styles.stock_high}>В наличии</span>
                    }

                    <div className={styles.price}>
                        <Price price={productPrice} currency={currency} />{' '}
                        {productComparePrice > 0 && <s><Price price={productComparePrice} currency={currency} /></s>}
                    </div>
                    
                    <div className={styles.actions}>
                        <button 
                            className={inbasket ? styles.inbasket : styles.add} 
                            disabled={productAvailable === '0' || productStatus === '0' || parseInt(productCount) === 0 || inbasket}
                            onClick={() => 
                                dispatch(addToCart({
                                    id: skuId, 
                                    name, 
                                    price: productPrice, 
                                    image_url: skuImageUrl.toString(), 
                                    sku: sku.name, 
                                    product_id: id,
                                    count: productCount
                                }))
                            }>{inbasket 
                                ? <>В корзине <Feather.FiCheck /></> 
                                : <>В корзину <Feather.FiShoppingCart /></> }
                        </button>
                    </div>

                    {/* {categories && categories.map((cat) => 
                        <Link key={cat.id} to={`/category/${cat.url}/`}>{cat.name}</Link>
                    )} */}
                    
                </form>
            </div> 
            
        </div>              
    )
}

export default ProductCart