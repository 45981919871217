import React, { useState } from 'react'
import style from './../../styles/Sorting.module.css'
import AsideMenu from '../AsideMenu/AsideMenu'
import { FiX, FiChevronDown } from "react-icons/fi"
import { BiSort } from "react-icons/bi";

const ProductsSorting = ({changeSorting, selectedSort}) => {
    // &sort=minimum_price&order=asc
    // price&order=asc

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar((sidebar) => !sidebar);

    const sortings = [
        // { 'id': 'minimum_price', 'name': 'по минимальной цене артикулов' },
        { 'id': 'name ASC', 'name': 'По наименованию' },
        { 'id': 'create_datetime DESC', 'name': 'Дата добавления' },
        { 'id': 'price ASC', 'name': 'Самые дешевые' },
        { 'id': 'price DESC', 'name': 'Самые дорогие' },
        { 'id': 'count DESC', 'name': 'В наличии' },
    ]
    const sorting = sortings.find(({id}) => id === selectedSort)

    const handleSortingsAsideSubmit = (e) => { 
        e.preventDefault();
        let param = ''
        const formData = new FormData(e.currentTarget);
        for (let [_, value] of formData.entries()) {
            param = value               
        }   
        changeSorting(param);
        showSidebar();
    };
    
    return (
        <aside className={style.wrap}>
            <span className='hide-on-small-only'>Сортировка:</span>
        
            {/* <label className={style.option}>
                <select
                    className={style.select} 
                    name="selectedSorting"
                    value={selectedSort} 
                    onChange={changeSorting}
                >
                {sortings && sortings.map((sort, index) => (
                    <option key={index} value={sort.id}>
                        {sort.name}   
                    </option>
                ))}    
                </select>  
            </label> */}

            <span onClick={ showSidebar } className={style.button}>
                <BiSort /> {sorting.name} <FiChevronDown />
            </span>
            <AsideMenu onClose={showSidebar} open={sidebar}>
                <form className={style.sidebar_container} onSubmit={handleSortingsAsideSubmit}>
                    <div className={style.sidebar_header}>
                        <h3>Сортировка</h3> <FiX className={style.close} onClick={ showSidebar }/>
                    </div>
                    <div className={style.sidebar_content}>
                        {sortings && sortings.map((sort, index) => (
                            <label key={index}>
                                <input
                                    name={'sorting'}
                                    // onChange={changeSorting} 
                                    type='radio' 
                                    defaultChecked={sort.id === selectedSort} 
                                    value={sort.id}
                                /> 
                                {sort.name}   
                            </label>
                        ))}
                    </div>
                    <div className={style.sidebar_action}>
                        {/* <button type="button" onClick={ showSidebar }>Применить</button> */}
                        <button type="submit">Применить</button>
                    </div>
                </form>
            </AsideMenu> 
        </aside>
    )
}

export default ProductsSorting
