import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productAPI } from "../../api/api";

export const getProduct = createAsyncThunk(
    "product/getProduct",
    async (id, thunkAPI) => {
        try {
            const res = await productAPI.getInfo(id);
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);  
export const getSkus = createAsyncThunk(
    "product/getSkus",
    async (id, thunkAPI) => {
        try {
            const res = await productAPI.getSkus(id);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
); 

const productSlice = createSlice({
    name: "product",
    initialState: {
        items: {},
        isLoading: false,
        skus: null,
        isSkusLoading: false,
    },
    reducers: {
        // filterByPrice: (state, { payload }) => {
        //   state.filtered = state.list.filter(({ price }) => price < payload);
        // },
        // getRelatedProducts: (state, { payload }) => {
        //   const list = state.list.filter(({ category: { id } }) => id === payload);
        //   state.related = shuffle(list);
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(getProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProduct.fulfilled, (state, { payload }) => {
            state.items = payload;
            state.isLoading = false;
        });
        builder.addCase(getProduct.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getSkus.pending, (state) => {
            state.isSkusLoading = true;
        });
        builder.addCase(getSkus.fulfilled, (state, { payload }) => {
            state.skus = payload;
            state.isSkusLoading = false;
        });
        builder.addCase(getSkus.rejected, (state) => {
            state.isSkusLoading = false;
        });
    },
});

export default productSlice.reducer;