import React from 'react'
import style from "../../styles/Products.module.css";

const ProductsItemImages = ({ images, defaultImageId, imageId,setImageId }) => {
    const gallery = Object.keys(images)
        .toSorted((a, b) => a === defaultImageId ? b - a : a - b)

    // const gallery = [];
    // for ( let i=0; i < count; i++) {
    //     gallery.push(i);
    // }

    return <>
        <span className={style.gallery} 
            data-default={defaultImageId} 
            onMouseOut={() => setImageId(defaultImageId)}>
            {gallery.map(id => 
                <span 
                    key={id}
                    data-id={id} 
                    onMouseEnter={() => setImageId(id)}  
                ></span>
            )}  
        </span>
        <span className={style.gallery_pagination}>
            {gallery.map(id => 
                <span key={id} className={id === imageId ? style.active : ''}></span>)}  
        </span>
    </>
}

export default ProductsItemImages