import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSetBestsellers, getSetPromo } from './../../features/products/productsSlice'
import Promo from '../Promo/Promo'
import Products from './../Products/Products'
import ProductsSkeleton from './../Products/ProductsSkeleton'
import ProductsItem from './../Products/ProductsItem'
import styles from "../../styles/Products.module.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import CategoriesGrid from '../Categories/CategoriesGrid'
// import AsideMenu from '../AsideMenu/AsideMenu'

const Home = () => {
    const { cart } = useSelector(({ cart }) => cart);
    const dispatch = useDispatch();
    const { 
        bestsellers, isLoadingBestsellers, 
        promo, isLoadingPromo 
    } = useSelector(({ products }) => products);
    const { categories, isLoading } = useSelector(({ categories }) => categories);

    useEffect(() => {     
        if (bestsellers === null) dispatch(getSetBestsellers())
        if (promo === null)  dispatch(getSetPromo())      
    }, [dispatch, bestsellers, promo]);

    return (
        <div className="home">
            <Promo />

            <section className="container">
            {isLoading 
                ? 'Загружаю ...'
                : <CategoriesGrid items={categories} />
            }
            </section>

            <section className="container">               
                <h1>Лучшие предложения</h1>
                {isLoadingBestsellers 
                    ? <ProductsSkeleton count={5} column='col s6 m4 l20' />               
                    : <Products products={bestsellers} column='col s6 m4 l20' />
                }
            </section>

            <section className="container">                
                <h1>Новинки каталога</h1>
                {isLoadingPromo ? (
                    <ProductsSkeleton count={5} column='col s6 m4 l20' />               
                ) : (                   
                    <Swiper modules={[Navigation, Pagination]} 
                        // pagination={true} 
                        // direction={'vertical'}
                        navigation={true} 
                        slidesPerView={5} 
                        spaceBetween={20} 
                        className={styles.list}
                        breakpoints={{
                            0: { slidesPerView: 2 },
                            601: { slidesPerView: 3},
                            993: { slidesPerView: 5 }
                        }} 
                    >
                        {promo && promo.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className={styles.product}>
                                <ProductsItem item={item} cart={cart} />
                            </div>
                        </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </section>

        </div>
    )
}

export default Home