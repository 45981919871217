import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  getProducts } from '../../features/products/productsSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Products from '../Products/Products';
import Paginator from '../common/Paginator/Paginator';
import style from "../../styles/Category.module.css";
import Filters from '../Filters/FiltersMod2';
import { findNestedElementUrl } from './../../utils/findItemNested'
import Skeleton from '../Products/ProductsSkeleton';
import ProductsSorting from '../Products/ProductsSorting';
import CategoriesInline from '../Categories/CategoriesInline';
import FiltersCurrent from '../Filters/FiltersCurrent/FiltersCurrent';
import { getCategoryFilters, selectedIitemFilter, resetSelectedItemFilter } from '../../features/feature/featureSlice';
import { useScreenWidth } from '../common/ScreenWidth/ScreenWidth'
import CategoryBreadcrumbs from './CategoryBreadcrumbs';
import FiltersAside from '../Filters/FiltersAside/FiltersAside';


const Category = ( ) => {
    const screenWidth = useScreenWidth();
    const [selectedSort, setSelectedSort] = useState('name ASC');
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { categories, price_range } = useSelector(({ categories }) => categories);

    // для пагинации
    const navigate = useNavigate();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    // const formRef = useRef(null);

    const category = findNestedElementUrl(categories, slug, "categories");
    const [id, setId] = useState(null);

    // let id = null;
    // if (category) id = category.id;
    
    useEffect(() => {
        if (!category) return
        setId(category.id)
    }, [ category ]);

    const [page, setPage] = useState( parseInt("" + params.get('page')) || 1 );
    const [totalCount, setTotalCount] = useState(0);

    // фильтр
    const [filters, setFilters] = useState('');
    const [selectedFilters, setSelectedFilters] = useState([]);
    const { initialFilter, isLoadingFeatures } = useSelector(({ features }) => features);
    const { initialFilterItems=[] } = initialFilter
    const [isChange, setIsChange] = useState(false);

    useEffect(() => { 
        if (!category) return
        dispatch( getCategoryFilters( category.filter ));
    }, [ id, isLoadingFeatures ]);

    useEffect(() => { 
        setFilters('')
        setSelectedFilters([])
    }, [ id ]);

    

    // useEffect(() => {
    //     if (!category || category.sort_products === null) return;
    //     dispatch(getSort(category.sort_products.replace(' DESC', '&order=desc').replace(' ASC', '&order=asc')))
    // }, [category, dispatch]);

    const { products, pageSize, count, isLoading } = useSelector(({ products }) => products);

    useEffect(() => {
        if (!id) return; 
        setPage( parseInt("" + params.get('page')) || 1 );
        let limit = pageSize;
        let offset = limit * page - limit;
        let sort = selectedSort.replace(' DESC', '&order=desc').replace(' ASC', '&order=asc')

        // setTimeout(() => {
            dispatch(getProducts({ hash: `category/${id}`, offset, limit, filters, sort }));
        // }, 600);
                
    }, [ selectedSort, dispatch, page, id, filters ]); 

    // useEffect(() => {
    //     if (!category || category.sort_products === null) return;
    //     setSelectedSort(category.sort_products);
    // }, [category]);

    const onPageChanged = (pageNamber) => {
        setPage(pageNamber);
        if (pageNamber > 1) {
            navigate('?page=' + pageNamber)
        } else {
            navigate('')
        }

        let limit = pageSize;
        let offset = limit * pageNamber - limit;
        dispatch(getProducts({ hash: `category/${id}`, offset, limit, filters }));
    }
    
    // работает, обнуляет, может ухудшить производительность
    // useLayoutEffect(() => {
    //     setTotalCount(0)
    // }, [id]);
    
    useEffect(() => {
        // if (!count) return; 
        setTotalCount(count)      
    }, [ count ]);

    const handleFilterSubmit = (event) => {
        // let params = {}
        // // let selectedParams = []
        // const formData = new FormData(event.currentTarget);
        event.preventDefault();
        setIsChange(!isChange)
        setPage(1)
        navigate('')

        // for (let [key, value] of formData.entries()) {
        //     if (value !== '') {
        //         params[key] = value            
        //         // selectedParams.push({'id': key.match(/\[(.*?)\]/)[1], 'value': value})
        //     }    
        // }
        // setFilters(new URLSearchParams(params).toString())
        // setPage(1)
        // navigate('')

        // setSelectedFilters(selectedParams)
        
        //const filters = new URLSearchParams(params).toString();
        // url = Object.keys(data).map(function(k) {
        //     return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        // }).join('&')     
    }

    // сброс фильтра
    const handleFilterReset = (event) => {        
        event.preventDefault();

        dispatch( resetSelectedItemFilter() )
        setFilters('');
        setPage(1);
        navigate('')

        // const itemsSelected = initialFilterItems
        // .filter((item) => item.isChecked === true);
        // setSelectedFilters(itemsSelected)
        //
        setIsChange(!isChange)   
    }

    // выбор фильтра
    const handleInputChange = (e, isApply = false) => {     
        dispatch( selectedIitemFilter({
            id: e.target.id,
            type: e.target.type,
            value: e.target.value,
            checked: e.target.checked, 
            name: e.target.name
        }) )
        if (isApply) {
           setIsChange(!isChange)
            setPage(1)
            navigate('') 
        }            
    };

    // const handleInputRange = (range) => { 
    //     range.map(r => (
    //         dispatch( selectedIitemFilter({
    //             type: r.type,
    //             value: r.value,
    //             name: r.name
    //         }))
    //     ))      
    //     setIsChange(!isChange)
    //     setPage(1)
    //     navigate('')         
    // };
    const handleCurrentChange = ({name, id, type, value}) => {
        if (type === 'number') value = ''         
        dispatch( selectedIitemFilter({
            id, type, checked: false, name, value
        }) )
        setIsChange(!isChange)         
    };
    
    useEffect(() => {
        // setTimeout(() => {
            let params = {}
            selectedFilters.map(({name, value}) => {
                if (value !== '') params[name] = value 
            })            
            setFilters(new URLSearchParams(params).toString())
        // }, 1000);
    }, [ selectedFilters ]);

    useEffect(() => {
        const itemsSelected = initialFilterItems
        .filter((item) => 
            item.type === 'number' ? 
                item.value !== ''
            : item.type === 'checkbox' ?
                item.isChecked === true
            : (item.type === 'radio' && item.value !== '') &&
                item.isChecked === true
        );
        setSelectedFilters(itemsSelected)
    }, [ isChange ]);
    
    const changeSorting = (e) => {
        if (e.target) {
           setSelectedSort(e.target.value); 
        } else {
            setSelectedSort(e); 
        }    
    }
    
    const getCount = (category, pageSize) => {
        if (category.count === '0' || category.count > pageSize) {
            return pageSize 
        } else {
            return category.count
        }
    }

    return ( category &&
        <div className="container">
        
            <CategoryBreadcrumbs categories={categories} category={category} />

            <h1 className={style.header} data-count={(!isLoading && totalCount > 0) ? totalCount : ''}>
                {category.name}
            </h1>

            {category?.categories.length > 0 && 
                <CategoriesInline items={category.categories} />                     
            }

            <div className='row'>
                <div className='col s12 m12 l2 hide-on-med-and-down'>
                {screenWidth > 992 &&
                    <Filters
                        isApply={true}
                        initialFilter={initialFilter}
                        isLoadingFeatures={isLoadingFeatures} 
                        // category_filter={category.filter}
                        handleSubmit={handleFilterSubmit}
                        handleInputChange={handleInputChange}
                        // handleInputRange={handleInputRange}
                        handleReset={handleFilterReset}
                        price_range={price_range}
                        category_id={id}
                    />
                }
                </div>
                <div className={`col s12 m12${category.filter === null ? ' l12' : ' l10'}`}>
                    <FiltersCurrent 
                        selectedFilters={selectedFilters}
                        isLoadingFeatures={isLoadingFeatures} 
                        initialFilterItems={initialFilterItems}
                        handleInputChange={handleCurrentChange}
                        handleReset={handleFilterReset}
                    />
                    <section className={style.controls_wrap}>
                        <ProductsSorting selectedSort={selectedSort} changeSorting={changeSorting} /> 
                        {category.filter !== null &&  
                            <FiltersAside 
                                selectedCount={selectedFilters.length}
                                initialFilter={initialFilter}
                                // category_filter={category.filter}
                                handleSubmit={handleFilterSubmit}
                                handleInputChange={handleInputChange}
                                // handleInputRange={handleInputRange}
                                handleReset={handleFilterReset}
                                price_range={price_range}
                                category_id={id} 
                            />
                        }                      
                    </section>
                    

                    {/* {isLoading ? 'Загружаю' : 'Всё'}
                    {getCount(category, pageSize)} */}

                    {isLoading ? (
                        <Skeleton count={getCount(category, pageSize)} 
                            column={category.filter === null ? 'col s6 m4 l20' : 'col s6 m4 l3'} />
                        // <div className="preloader">Loading...</div> 
                    
                    ) : !products.length ? (                
                        filters 
                            ? <div>
                                Не нашлось товаров с такими параметрами{' '} 
                                <button onClick={handleFilterReset}>
                                    Сбросить
                                </button>
                            </div> 
                            : "Нет товаров в категории"            
                    ) : (
                        <Products products={products} 
                            column={category.filter === null ? 'col s6 m4 l20' : 'col s6 m4 l3'} /> 
                    )}

                    <Paginator 
                        currentPage={page} 
                        onPageChanged={onPageChanged} 
                        pageSize={pageSize}
                        totalCount={totalCount}
                        portionSize={8}
                    />
                    
                </div>
            </div>
        </div>
    )
}

export default Category