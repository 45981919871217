import React from 'react'
import { NavLink } from 'react-router-dom'
import style from '../../styles/Header.module.css'

const HeaderTop = () => {
    return (
        <div className={style.top}>
            <div className='container'>
                <NavLink to={`/`}>Покупателям </NavLink>
                <NavLink to={`/`}>Доставка и оплата </NavLink>
            </div>
        </div>    
  )
}

export default HeaderTop