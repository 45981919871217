import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCustomer } from "../../features/customer/customerSlice";

import styles from "../../styles/User.module.css";

const CustomerAddForm = ( ) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        "data[name]": "",
        'data[email]': "",
        'data[phone]': "",
        address: []
    });

    const handleChange = ( { target: { value, name } } ) => {
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isNotEmpty = Object.values(values).every((val) => val);

        if (!isNotEmpty) return;

        dispatch(addCustomer(values));
    };

    return (
        
        <form className={styles.form} onSubmit={handleSubmit}>
            Ваши данные
            <div className={styles.group}>
                <input
                    type="name"
                    placeholder="Полное имя"
                    name="data[name]"
                    value={values.name}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className={styles.group}>
                <input
                    type="email"
                    placeholder="Email-адрес"
                    name="data[email]"
                    value={values.email}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className={styles.group}>
                <input
                    type="text"
                    placeholder="Телефонный номер"
                    name="data[phone]"
                    value={values.phone}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                />
            </div>

            <button type="submit" className={styles.submit}>
                К оформлению
            </button>
        </form>
    );
};

export default CustomerAddForm;