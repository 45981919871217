import React from 'react'
import { Link } from 'react-router-dom'
import style from './Categories.module.css'
import * as Feather from "react-icons/fi";

const CategoriesGrid = ({items}) => {
    return (
        <div className={`${style.grid_wrap} row flex wrap`}>
            {items && items.map((c) => 
                <div key={c.id} className={`${style.col} col s6 m4 l3`}>
                    <Link to={`/category/${c.url}/`}>
                        <Feather.FiStar /> 
                        <span>{c.name}</span>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default CategoriesGrid