import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productAPI, categoryAPI } from "../../api/api";

export const getPreSearchProducts = createAsyncThunk(
    "presearch/getPreSearchProducts",
    async (value, thunkAPI) => {
        try {
            const res = await productAPI.search(`search/query=${value}`, 0, 5, '');
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);
export const getPreSearchCategories = createAsyncThunk(
    "presearch/getPreSearchCategories",
    async (name, thunkAPI) => {
        try {
            const res = await categoryAPI.search(name);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const preSearchSlice = createSlice({
    name: "presearch",
    initialState: {
        products: [],
        categories: [],
        count: 0,
        isLoadingSearchProducts: false,
    },
    reducers: {
        clearSearchProducts: (state) => {
            state.products = []
            state.categories = [];
            state.count = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPreSearchProducts.pending, (state) => {
            state.isLoadingSearchProducts = true;
        });
        builder.addCase(getPreSearchProducts.fulfilled, (state, { payload }) => {
            state.products = payload.products;
            state.count = payload.count;
            state.isLoadingSearchProducts = false;
        });
        builder.addCase(getPreSearchProducts.rejected, (state) => {
            state.isLoadingSearchProducts = false;
        });
        builder.addCase(getPreSearchCategories.fulfilled, (state, { payload }) => {
            state.categories = payload;
        });
        
    },
});
  
export default preSearchSlice.reducer;
export const {
    clearSearchProducts
} = preSearchSlice.actions;