import React from 'react'
import { Link } from 'react-router-dom';

function getHighlightedText(text, highlight) {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, i) => 
        <span  key={i} 
            style={part.toLowerCase() === highlight.toLowerCase() ? {fontWeight: 'bold'} : {} }>
            {part}
        </span>)
}

const PreSearchItem = ({ FiExternalLink, style, name, url, searchValue, setIsOpen}) => {
    return (
        <Link
            onClick={()=> setIsOpen(false)}
            className={style.suggests_item} 
            to={url}
        >
            <span className={style.suggests_item_name}>
                {getHighlightedText(name, searchValue)}
            </span> 
            <FiExternalLink />
        </Link>
    )
}

export default PreSearchItem