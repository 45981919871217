import React from 'react'
import { Link } from 'react-router-dom'
import style from './Categories.module.css'

const CategoriesInline = ({items}) => {
    return (
        <div className={style.inline_wrap}>
            {items && items.map((c) => 
                <Link key={c.id} to={`/category/${c.url}/`}>
                    <span>{c.name}</span>
                </Link>
            )}
        </div>
    )
}

export default CategoriesInline