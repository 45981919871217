import React from "react";

let Price = ({price = 0, currency = 'RUB'}) => {
    const options = { 
        style: "currency", 
        currency: currency,
        minimumFractionDigits: 0 
    };
    const numberFormat = new Intl.NumberFormat("ru-RU", options);
    return <>
        {price && 
            <span className="price">
                {numberFormat.format(price)}
            </span>
        }
    </>
}

export default Price;