export const DOMAIN = 'https://sofeel.vmodern.ru'
export const BASE_URL = 'https://sofeel.vmodern.ru/api.php'
export const HEADERS_AUTHORIZATION = 'Bearer 13bb1ceefeae076eb91b25be8361f997'

// export const DOMAIN = 'https://pb39.ru'
// export const BASE_URL = 'https://pb39.ru/api.php'
// export const HEADERS_AUTHORIZATION = 'Bearer c99f7c132219c682751f67acef7df4e0'

// export const DOMAIN = 'https://vsekondicioneri.ru'
// export const BASE_URL = 'https://vsekondicioneri.ru/api.php'
// export const HEADERS_AUTHORIZATION = 'Bearer e40e511483b47f6b9905912588a9f8c7'

///api.php/auth?response_type=token&client_id=ShopModernApp&client_name=ShopModernApp&scope=shop,photos,blog&redirect_uri=https://pb39.ru/