import React from 'react'
import styles from "../../styles/Products.module.css";

const ProductsSkeleton = ({ count, column = 'col s6 m4 l3'}) => {
    let skeletons = [];
    for ( let i=1; i <= count; i++) {
        skeletons.push(i);
    }
    return (
        <div className='row flex wrap'>
            {skeletons.map(s => {
                return <span key={s} className={column}>
                    <span className={styles.skeleton}></span>
                </span>
            }) }
        </div>
    )
}

export default ProductsSkeleton