import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsAPI } from "../../api/api";

export const getSettings = createAsyncThunk(
    "settings/getSettings",
    async (_, thunkAPI) => {

        try {
            const res = await settingsAPI.get();
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
  );  

  const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        settings: [], 
        isLoading: false,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getSettings.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSettings.fulfilled, (state, { payload }) => {
            state.settings = payload.settings;
        });
        builder.addCase(getSettings.rejected, (state) => {
            state.isLoading = false;
        });
    },
  });
  
  export default settingsSlice.reducer;