import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setInitialFilter } from '../../features/feature/featureSlice';
import styles from "../../styles/Category.module.css";
import { getPriceRange } from '../../features/categories/categoriesSlice';
// import MultiRangeSlider from "../common/multiRangeSlider/MultiRangeSlider";


const Filters = ({ isApply, isLoadingFeatures, initialFilter, category_id, handleInputChange, /*handleInputRange,*/ price_range }) => {
    
    // const { isLoadingFeatures } = useSelector(({ features }) => features);
    // const { features, isLoading, initialFilter } = useSelector(({ features }) => features);
    const {items_filter = [], initialFilterItems = []} = initialFilter
    // const categoryFiltersArr = category_filter ? category_filter.split(',') : []
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPriceRange(category_id));   
    }, [dispatch, category_id]);

    // const getInitialFilterItems = (features, categoryFiltersArr) => {
    //     const initial = [];
 
    //     // поля фильтра
    //     const featuresWidthPrice = Object.values({...features})
    //         featuresWidthPrice.unshift({ id: 'price', code: 'price', name: 'Цена', builtin: '0 ', type: 'range.double'})
    //     const items_filter = featuresWidthPrice
    //         .filter(item => categoryFiltersArr.includes(item.id))
    //         .filter(item => item.builtin == 0)

    //     // инициализирую начальные значения фильтра
    //     items_filter.map(({ code, type, values }) => {
    //         if (code === 'price') {
    //             if (!initial.find(item => item.code === 'price')) {
    //                 initial.push(
    //                     { code, name: 'filters[price_min]', range: 'min', value: '', type: 'number' },
    //                     { code, name: 'filters[price_max]', range: 'max', value: '', type: 'number' },
    //                     { code, name: 'filters[price]', value: 0, type: 'range' }
    //                 )
    //             }
    //         } else if (type == 'boolean') {
    //             initial.push(
    //                 { id: `${code}-1`, code, name: `filters[${code}]`, label: 'Да', value: '1', type: 'radio', 'isChecked': false },
    //                 { id: `${code}-2`, code, name: `filters[${code}]`, label: 'Нет', value: '0', type: 'radio', 'isChecked': false },
    //                 { id: `${code}-3`, code, name: `filters[${code}]`, label: 'Не важно', value: '', type: 'radio', 'isChecked': true }
    //             ) 
    //         } else if (type === 'range.double') {              
    //             initial.push(
    //                 { code, name: `filters[${code}][min]`, range: 'min', value: '', type: 'number' },
    //                 { code, name: `filters[${code}][max]`, range: 'max', value: '', type: 'number' }
    //             )      
    //         } else if (type === 'color' || type === 'varchar') {
    //             values.map((p, i) => { 
    //                 initial.push({
    //                     code,
    //                     name: `filters[${code}][${i}]`,
    //                     label: p.value, 
    //                     value: p.id,
    //                     type: 'checkbox',
    //                     'isChecked': false   
    //                 })     
    //             })
    //         }
    //     })
    //     const initialFilterItems = [...initial]
    //     return {items_filter, initialFilterItems}  
    // }

    // useEffect(() => {   
    //     dispatch( setInitialFilter( category_filter ));     
    //     // dispatch( setInitialFilter( getInitialFilterItems(features, categoryFiltersArr) ));       
    // }, [ category_filter, isLoading ]);

    
    return isLoadingFeatures ? (
        <section className="preloader">Loading...</section>
    ) : items_filter.length > 0 && (

        <div className={styles.filters}>

            {items_filter.map((filter, i) =>
                <div key={i} className={styles.filter}>
                    <div className={styles.filter_name}>{filter.name}</div>
                    {initialFilterItems.map((input, i) =>
                        input.code === filter.code &&
                        <Fragment key={i}>
                            {input.type === 'checkbox' &&
                            <FilterCheckbox 
                                handleInputChange={(e) => handleInputChange(e, isApply)} 
                                value={input.value}
                                label={input.label} 
                                name={input.name}
                                isChecked={input.isChecked}
                                color={input.color} 
                            />
                            }
                            {input.type === 'radio' &&
                            <FilterRadio 
                                handleInputChange={(e) => handleInputChange(e, isApply)}
                                id={input.id} 
                                code={input.code}
                                value={input.value}
                                label={input.label} 
                                name={input.name}
                                isChecked={input.isChecked} 
                            />
                            }
                            {input.type === 'number' &&
                            <FilterRange 
                                handleInputChange={(e) => handleInputChange(e, isApply)} 
                                value={input.value}
                                name={input.name}
                                label={input.label} 
                                placeholder={
                                    `${input.range === 'min' 
                                    ? 'от': 'до'} ${input.code === 'price' ? 
                                    price_range[input.range] : ''}`
                                }
                                min={input.code === 'price' ? price_range?.min : 0} 
                                max={input.code === 'price' ? price_range?.max : 1000}
                            />
                            }
                            {/* {(input.type === 'range') && 
                                price_range.max > 0 &&
                                <MultiRangeSlider
                                    min={price_range?.min} 
                                    max={price_range?.max}
                                    onChange={({ min, max }) => handleInputRange([
                                        {name: 'filters[price_min]', type: 'number', value: min},
                                        {name: 'filters[price_max]',  type: 'number', value: max}
                                    ])}
                                />    
                            } */}
                            {/* {input.type === 'range' && 
                                <input id={filter.code} 
                                    type="range" 
                                    min={input.code === 'price' ? price_range?.min : ''} 
                                    max={input.code === 'price' ? price_range?.max : ''} 
                                    value={input.value} 
                                    step="1"
                                    onChange={e => handleInputChange(e)}
                                    multiple  
                                />
                            } */}
                            
                        </Fragment>
                    )}
                    

                    {/* {filter.type == 'boolean' 
                        ? <FilterRadio handleInputChange={handleInputChange} 
                                code={filter.code} 
                            /> 
                        : filter.type == 'range.double'
                        ? <FilterMinMax handleInputChange={handleInputChange} 
                                min={'Минимально'} 
                                max={'Максимально'} 
                                code={filter.code} 
                            />
                        : filter.values
                        ? <FilterCheckbox handleInputChange={handleInputChange} 
                                values={filter.values} 
                                code={filter.code} 
                            />
                        : ''
                    } */}
                </div>
            )}
        </div>
    )  
}

const FilterCheckbox = ({ color, label, value, name, isChecked, handleInputChange }) => {
    return <label>
        <input
            type="checkbox" 
            name={name}
            value={value}
            onChange={handleInputChange}
            checked={isChecked}
        /> 
        {color && <span className={styles.color} style={{ 'background': color }}></span>} {label}
    </label>
}
const FilterRadio = ({ id, label, value, name, isChecked, handleInputChange }) => {
    return <label>
        <input
            id={id}
            type="radio" 
            name={name}
            value={value}
            onChange={handleInputChange}
            checked={isChecked}
        /> 
        {label}
    </label>
}
const FilterRange = ({ min, max, placeholder, value, name, handleInputChange }) => {
    return <input
            type="number" 
            name={name}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            min={min} 
            max={max}
        />
}
// const FilterCheckbox = ({ values, code, handleInputChange }) => {
//     return <div className={styles.filter_group}>
//         {values instanceof Array && 
//             values.map((v, i) => 
//                 <label key={i}>
//                     <input 
//                         type="checkbox" 
//                         name={`filters[${code}][${i}]`}
//                         value={v.id ? v.id : i + 1}
//                         onChange={e => handleInputChange(e)}
//                     /> 
//                     {v.value ? v.value : v}
//                 </label>
//             )}
//     </div>
// }
// const FilterRadio = ({ code, handleInputChange }) => {
//     const values = [
//         {name:'Yes', value:'1'}, {name:'No', value:'0'}, {name:'Any', value:''}
//     ]
//     return <div className={styles.filter_group}>
//         {values.map(({ name, value }, i) => 
//             <Fragment key={i}>
//                 <input id={`${code}${i}`} 
//                     type="radio" 
//                     name={`filters[${code}]`} 
//                     defaultValue={value}
//                     onChange={e => handleInputChange(e)}
//                     defaultChecked={value === ''}
//                 />
//                 <label htmlFor={`${code}${i}`}>{name}</label>
//             </Fragment>   
//         )}
//     </div>
// }
// const FilterPrice = ({ min, max, handleInputChange }) => {
//     return <div className={styles.filter_inputs}>
//         <input type="number" 
//             name={`filters[price_min]`} 
//             placeholder={`от ${min}`} 
//             min={min} 
//             max={max}
//             onChange={event => handleInputChange(event)} 
//         />
//         <input type="number" 
//             name={`filters[price_max]`} 
//             placeholder={`до ${max}`} 
//             min={min} 
//             max={max}
//             onChange={event => handleInputChange(event)} 
//         />
//     </div>
// }
const FilterMinMax = ({min, max, code, handleInputChange }) => {
    return <div className={styles.filter_inputs}>
        <input type="number" 
            name={`filters[${code}][min]`} 
            placeholder={min}
            onChange={event => handleInputChange(event)}
         />
        <input type="number" 
            name={`filters[${code}][max]`} 
            placeholder={max}
            onChange={event => handleInputChange(event)} 
        />
    </div>
}

export default Filters