import React from 'react'

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <div className='footer'>
            <div className='container'>
                <p>{year} © Все права и материалы защищены</p>
            </div> 
        </div>
    )
}

export default Footer