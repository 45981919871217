//import './total.css'
// import {useSelector} from 'react-redux'
import Price from '../common/Price/Price'

const Total = ({ cart }) => {

    // const {cart} = useSelector(({ cart }) => cart)

    const getTotal = () => {
        let totalQuantity = 0
        let totalPrice = 0
        cart.forEach(item => {
            if ( !item.checked ) return;
            if ( item.disabled ) return;
            totalQuantity += item.quantity
            totalPrice += item.price * item.quantity
        })
        return {totalPrice, totalQuantity}
    }
 
    return (
        <div className="total">
            {getTotal().totalQuantity > 0
            ?  <div>
                    <h2>Сумма заказа</h2>
                    <p>
                        Итого {getTotal().totalQuantity} товара на сумму <Price price={getTotal().totalPrice} />
                    </p>
                    <p>Доступные способы и время доставки можно выбрать при оформлении заказа</p>
                </div> 
            : <p>Выберите товары, чтобы перейти к оформлению заказа</p>
            }  
        </div>
    )
}

export default Total