import React, { memo, useEffect, useState } from "react";
import styles from "./Paginator.module.css";
import * as Feather from "react-icons/fi";

let Paginator = ({currentPage, totalCount, onPageChanged, pageSize, portionSize}) => {

    let pagesCount = Math.ceil(totalCount / pageSize);
    let pages = [];
    for ( let i=1; i <= pagesCount; i++) {
        pages.push(i);
    }

    let portionCount = Math.ceil(pagesCount / portionSize);
    let [portionNumber, setPortionNumber] = useState(1);
    let leftPortionPageNumber = (portionNumber - 1) * portionSize + 1;
    let rightPortionPageNumber = portionNumber * portionSize;
    const currentPotionNumber = Math.ceil((currentPage * (pagesCount / portionSize)) / pagesCount);

    useEffect(() => {
        setPortionNumber(currentPotionNumber)      
    }, [ currentPotionNumber ]);

    return (        
        <div className={styles.pagination}>
            
            { (pages && portionNumber > 1) &&
                <span onClick={ () => {setPortionNumber( portionNumber - 1 )} }><Feather.FiArrowLeft /></span>
            }
            { pages
            .filter(p => p >= leftPortionPageNumber && p <= rightPortionPageNumber)
            .map(p => {
                return <span key={p} 
                    className={ currentPage === p ? styles.selected : '' } 
                    onClick={currentPage !== p ? () => onPageChanged(p) : () => { return }}
                    >
                    {p}
                </span>
            }) }
            { portionCount > portionNumber &&
                <span onClick={ () => {setPortionNumber( portionNumber + 1 )} }><Feather.FiArrowRight /></span>
            }
        </div>       
    )
}

export default memo(Paginator);