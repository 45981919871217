import { useCallback, useEffect, useRef, useState } from "react";
import style from "./AsideMenu.module.css";
import { createPortal } from "react-dom";

export default function AsideMenu({ open, onClose, children, ...props }) {
    const asideRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const onClick = useCallback(
        ({ target }) => {
            const { current: el } = asideRef;
            if (target === el) onClose();
        },
        [onClose]
    );

    useEffect(() => {        
        if (open) {
            setIsVisible(true)
        } else {
            setTimeout(function() {
                setIsVisible(false)
            }, 300);
        }       
    }, [open]);

    if (isVisible)
    return createPortal(
        <div
            ref={asideRef} 
            className={open ? style.overlay_open : style.overlay}
            onClick={onClick}
        >
            <div
                // className={style.sidebar} 
                className={open ? style.sidebar_open : style.sidebar}
            >
                {children}
            </div>
        </div>
 
        , document.body
    )
}
