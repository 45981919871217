import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Products from '../Products/Products';
import ProductsSkeleton from '../Products/ProductsSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchProducts, getSearchCategories, clearSearchProducts } from '../../features/search/searchSlice';
import Paginator from '../common/Paginator/Paginator';
import CategoriesInline from '../Categories/CategoriesInline';
import style from './Search.module.css'

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState( parseInt(searchParams.get("page")) || 1 )
    const title = searchParams.get("query")
    const { products, isLoadingSearchProducts, count, pageSize, categories } = useSelector(({ search }) => search)
    const dispatch = useDispatch()

    useEffect(() => {  
        if (title.length === 0) {
            dispatch(clearSearchProducts())
        } else {
            dispatch(getSearchProducts({ 
                value: title, 
                offset: 0, 
                limit: pageSize
            }))
            dispatch(getSearchCategories(title)) 
        }           
    }, [ dispatch, title ]);

    const onPageChanged = (pageNamber) => {
        setPage(pageNamber);
        if (pageNamber > 1) {
            searchParams.set('page', pageNamber);       
        } else {
            searchParams.delete('page');
        }
        setSearchParams(searchParams)

        let limit = pageSize;
        let offset = limit * pageNamber - limit;
        dispatch(getSearchProducts({ 
            value: title, offset, limit 
        }))
    }
    // const onPageChanged = useCallback((pageNamber) => {
    //     setPage(pageNamber);
    //     if (pageNamber > 1) {
    //         searchParams.set('page', pageNamber);       
    //     } else {
    //         searchParams.delete('page');
    //     }
    //     setSearchParams(searchParams)

    //     let limit = pageSize;
    //     let offset = limit * pageNamber - limit;
    //     dispatch(getSearchProducts({ 
    //         value: title, offset, limit 
    //     }))
    // }, [ page, count ]);

    return (
        <div className="container">
            <h1 className={style.header} data-count={count}>
                Результат поиска «{title}»
            </h1>

            {categories.length > 0 && 
                <CategoriesInline items={categories} />                     
            }

            {isLoadingSearchProducts 
                ?  <ProductsSkeleton count={pageSize} column={'col s6 m4 l20'} />
                : <Products products={products} column={'col s6 m4 l20'} />
            }

            {(categories.length === 0 && count === 0) && 
                <p>К сожалению, мы ничего не нашли</p>}

            <Paginator 
                currentPage={page} 
                onPageChanged={onPageChanged} 
                pageSize={pageSize}
                totalCount={count}
                portionSize={8}
            />
        </div>
    )
}

export default Search