import React, { useState, useEffect, useRef } from 'react'
import style from '../../styles/Header.module.css'
import { Link, NavLink, useNavigate } from 'react-router-dom';
//import NavCategories from './../Nav/NavCategories';
import { ROUTES } from '../../utils/routes';
import { useSelector } from 'react-redux';
import { FiShoppingCart, FiMenu, FiX, FiSearch } from "react-icons/fi";
import HeaderTop from './HeaderTop';
import PreSearch from '../Search/PreSearch/PreSearch';

const Header = ( ) => {

    const navigate = useNavigate()
    const cart = useSelector((state) => state.cart.cart)
    const [catalogToggle, setCatalogToggle] = useState(false);
    const [itemIndex, setItemIndex] = useState(0);
    let menuRef = useRef();

    const getTotalQuantity = () => {
        let total = 0
        cart?.forEach(item => {
            total += item.quantity
        })
        return total
    } 
    const { categories } = useSelector(({ categories }) => categories);
    const handler = (e) => {
        // e.stopPropagation();
        if ( menuRef.current && 
            !menuRef.current.contains(e.target) ){   
            setCatalogToggle(false);
        }   
    };
    useEffect(() => {
        if (catalogToggle) {
            document.addEventListener("mousedown", handler);
            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }   
    }, [setCatalogToggle, catalogToggle]);

    // const { categoryName } = categories[itemIndex]

    // console.log(categories[0])
    // console.log(isLoading)

    return (
        <div className={style.header}>
            <HeaderTop />

            <div className="container">
                <div className={style.header_wrap}>

                    <div ref={menuRef} className={`${style.wrapper_logo} ${catalogToggle ? style.overlay : ''}`}>
                        <Link to={ROUTES.HOME}>Logo</Link>

                        <button 
                            className={style.catalog_button} 
                            onClick={() => setCatalogToggle(s => !s)}
                        >Каталог {catalogToggle ? <FiX /> : <FiMenu />}
                        </button>

                        {(categories.length > 0 && catalogToggle) && <div className={style.catalog_menu}>
                            <div className={style.catalog_menu_rootmenu}>
                                {categories.map(({ id, name, url }, index) => (
                                    <div key={id} 
                                        className={`${style.catalog_menu__root_item}${index === itemIndex ? ` ${style.root_item_active}` : ``}`} 
                                        onMouseOver={() => setItemIndex(index)}
                                    >
                                        <NavLink to={`/category/${url}/`} onClick={() => setCatalogToggle(false)}>
                                            {name}
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                            <div className={style.catalog_submenu}>

                                {/* <div className={style.catalog_submenu__first_level}>
                                    {categories[itemIndex]['name']}
                                </div> */}
                                {categories[itemIndex].categories.map(({ id, name, url, categories }) => (
                                    <div key={id} className=''>
                                        <NavLink className={style.catalog_submenu__first_level} 
                                            to={`/category/${url}/`} 
                                            onClick={() => setCatalogToggle(false)}>
                                            {name}
                                        </NavLink>
                                        <div className={style.catalog_submenu_columns}>
                                        {categories.map(({ id, name, url, categories }) => ( 
                                            <NavLink key={id} 
                                                className={style.catalog_submenu_item}
                                                to={`/category/${url}/`} 
                                                onClick={() => setCatalogToggle(false)}>
                                                {name}
                                            </NavLink> 
                                        ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>}
                    </div>
                                               
                    <PreSearch />                            

                    {/* <nav>    
                        <ul>
                            {categories.map(({ id, name, url }) => (
                                <li key={id}>
                                    <NavLink to={`/category/${url}/`}>{name}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav> */}

                    <div className={style.shopping_cart} onClick={() => navigate(ROUTES.CART)}>
                        <FiShoppingCart />
                        <span>{getTotalQuantity() || 0}</span>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Header
