import axios from 'axios';
import { BASE_URL, HEADERS_AUTHORIZATION } from "../utils/constants";

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Authorization': HEADERS_AUTHORIZATION
    }
});

export const categoryAPI = {
    // modern method из плагина
    async getPriceRange(id = 0) {
        const response = await instance.get(`shop.category.getMyPriceRange?id=${id}`);
        return response.data;
    },
    // Возвращает дерево категорий товаров.
    getTree(parent_id = 0, depth = 0) {
        return instance.get(`shop.category.getTree?parent_id=${parent_id}&depth=${depth}`)
            .then(response => { 
                return response.data.filter(item => item.status === '1');
            });
    },
    // Возвращает информацию о категории.
    getInfo(id) {
        return instance.get(`shop.category.getInfo?id=${id}`)
            .then(response => { 
                return response.data;
            });
    },
    // Возвращает список категорий всех уровней, являющихся родительскими по отношению к указанной категории.
    getParents(id, reverse = 0) {
        return instance.get(`shop.category.getParents?id=${id}&reverse=${reverse}`)
            .then(response => { 
                return response.data.filter(item => item.status === '1');
            });
    },
    async search(name) {
        const response = await instance.get(`shop.category.search?name=${name}`);
        return response.data;
    }
}
// encodeURIComponent('&filters[price_max]=2689&filters[zashchitnaya_plyonka]=6')
// filters[price_max]=2689&filters[zashchitnaya_plyonka]=6
//filters[in_stock_only]=1
// filters[price_max]=2689
// filters[zashchitnaya_plyonka]=6
// fields='image_count, images2x, skus_filtered, sku_filtered, skus'

export const productAPI = {
    async search(hash = 'category/1', offset=0, limit=20, fields='images2x,image_count', filters='', sort='') {
        const response = 
        await instance.get(`shop.product.search?hash=${hash}&offset=${offset}&limit=${limit}&fields=${fields}&${filters}&sort=${sort}`);
        return response.data;
    },
    async getInfo(id) {
        const response = await instance.get(`shop.product.getInfo?id=${id}`);
        return response.data;
    },
    async getSkus(id) {
        const response = await instance.get(`shop.product.skus.getList?product_id=${id}`);
        return response.data;
    }
}
// shop.feature.getList - стандартный метод
// shop.feature.getMyList - modern method из плагина
export const featureAPI = {
    async getList(type_id = null) {
        const response = await instance.get(`shop.feature.getMyList?${type_id && `type_id=${type_id}`}`);
        return response.data;
    }
}

export const settingsAPI = {
    // Возвращает значения настроек магазина.
    async get() {
        const response = await instance.get(`shop.settings.get`);
        return response.data;
    },
    async getContactFields(customer_fields = 1) {
        // Возвращает список контактных полей из настроек пошагового оформления заказа.
        const response = await instance.get(`shop.settings.getContactFields?customer_fields=${customer_fields}`);
        return response.data;
    }
}

// data: {
//     contact_id: '1',
//     comment: 'тестовый заказ',
//     items: [
//         {
//             sku_id: '491',
//             quantity: '2',
//             price: '15',
//         },
//           {
//             sku_id: '172',
//             quantity: '2',
//             price: '22',
//         }
//     ],
// }
export const orderAPI = {
    // Создает новый заказ.
    async add(data) {
        const response = await instance.post(`shop.order.add`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            },
            // contentType: "application/x-www-form-urlencoded",  
        });
        return response.data;
    },
    // Сохраняет информацию о заказе в базу данных.
    async save() {
        const response = await instance.get(`shop.order.save`);
        return response.data;
    }
}

export const customerAPI = {
    // Добавление нового покупателя.
    async add(data, skip_validation = 0) {
        const response = await instance.post(`shop.customer.add?skip_validation=${skip_validation}`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            },
        });
        return response.data;
    }
}