import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';

import Category from '../Category/Category';
import Home from '../Home/Home';
import { ROUTES } from '../../utils/routes';
import Product from '../Product/Product';
import Cart from '../Cart/Cart';
import { useLocation } from "react-router-dom";
import Search from '../Search/Search';

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const AppRoutes = () => <>
        <ScrollToTop />   
        <Routes>     
            <Route index element={<Home />}/>
            <Route path={ROUTES.CATEGORY} element={<Category />}/>       
            <Route path={ROUTES.PRODUCT} element={<Product />}/>
            <Route path={ROUTES.CART} element={<Cart />}/>
            <Route path={ROUTES.SEARCH} element={<Search />}/>
        </Routes> 
    </>
;

export default AppRoutes