import React from 'react'
import style from "./FiltersCurrent.module.css"
import * as Feather from "react-icons/fi";

const FiltersCurrent = ({ 
    selectedFilters, 
    initialFilterItems, 
    handleInputChange, 
    handleReset }) => {

    const currentFilters = initialFilterItems
        .filter(( item ) => selectedFilters.includes(item))
    
    function groupBy(data, keyFn) {
        let m = new Map();   
        for (let x of data) {
            let k = keyFn(x);
            if (!m.has(k))
                m.set(k, []);
            m.get(k).push(x);
        }   
        return m;
    }
    let currentFiltersGroup = [];
    for (let [code, items] of groupBy(currentFilters, x => x.code === 'price' ? x.code : x)) {
        currentFiltersGroup.push({code, filters: items.map(x => x)})
    }

    return  currentFilters.length > 0 && <div className={style.wrap}>
            {/* Выбранные фильтры ({currentFilters.length}):  */}

            {currentFiltersGroup.map(({filters}, i) =>
                <span key={i} className={style.picked} >
                    {filters.map(({name, id, type, label, value, range}, i) =>
                        <span  key={i} onClick={() => handleInputChange({ name, id, type })} >
                            {label ? label : `${range === 'min' ? 'от': 'до'} ${value}`} 
                        </span>
                    )}
                    <Feather.FiX />
                </span>
            )} 

            {/* {currentFilters.map(({name, id, type, label, value}, i) =>
                <span  key={i} 
                    className={style.picked}
                    onClick={() => handleInputChange({ name, id, type })} >
                    {label ? label : value} <Feather.FiX />
                </span>
            )}  */}
            <span className={`${style.picked_reset} ${style.picked}`} onClick={handleReset}>
                Сбросить фильтры <Feather.FiX />
            </span>
        </div>

}

export default FiltersCurrent