import React from 'react'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { findItemNested } from '../../utils/findItemNested';

const CategoryBreadcrumbs = ({categories, category}) => {
    const breadcrumbs = [
        {'name': 'Главная', 'url': `/`},   
    ]
    if (categories && category?.parent_id !== '0') {
        const parentCategory = findItemNested(categories, category?.parent_id, "categories");
        breadcrumbs[1] = {'name': parentCategory?.name, 'url': `/category/${parentCategory?.url}/`}    
    } 
    if (category) breadcrumbs[2] = {'name': category?.name, 'url': `/category/${category?.url}/`}

    return <Breadcrumbs items={breadcrumbs} />

}

export default CategoryBreadcrumbs