import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { getProduct } from '../../features/product/productSlice';
import ProductCart from './ProductCart';
import ProductFeatures from './ProductFeatures';
import ProductDescription from './ProductDescription';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ProductRelated from './ProductRelated';


const Product = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!id) return;
        dispatch(getProduct(id));   
    }, [dispatch, id]);

    const { items, isLoading } = useSelector(({ product }) => product);
    const { features, type_id, description, categories, category_id, cross_selling, upselling} = items;
    const category = categories && categories.find(({ id }) => id === category_id );

    const breadcrumbs = [
        {'name': 'Главная', 'url': `/`},
        {'name': category?.name, 'url': `/category/${category?.url}/`},
        {'name': items?.name, 'url': null},
    ]
    
    return isLoading ? (
        <section className="preloader">Loading...</section>

    ) : Object.entries(items).length > 0 && (

        <div className="container">
            <Breadcrumbs items={breadcrumbs} />
            <ProductCart {...items} />
            <ProductDescription description={description} />
            {features && <ProductFeatures type_id={type_id} product_features={features} />}
            <ProductRelated cross_selling={cross_selling} upselling={upselling} />
        </div>
    )
};

export default Product;
