import React from 'react'

const ProductDescription = ({ description }) => {

    // function stripHtml(dirtyString) {
    //     const doc = new DOMParser().parseFromString(dirtyString, 'text/html');
    //     return doc.body.textContent || '';
    // }

    return description &&
        <section>
            <h2>Описание</h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            {/* {stripHtml(description)} */}
        </section>

}

export default ProductDescription