import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import style from './Cart.module.css'
import { removeItems, checkedAllItems } from '../../features/cart/cartSlice'
import Modal from "../ModalDialog";

const CartControls = ({ isCheckedControls, countChecked }) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);

    const handleOnChangeAllItems = () => {
        dispatch(checkedAllItems(!isCheckedControls))
    };
    const removeItemsDialog = () => {
        dispatch(removeItems())
        setIsOpen(false)
    }

    return (
        <div className={style.controls}>
            <label>
                <input 
                    type="checkbox" 
                    name="checked_all_items" 
                    onChange={() => handleOnChangeAllItems()} 
                    checked={isCheckedControls} 
                />
                Выбрать все
            </label>
            
            {countChecked !== 0 &&
                <button
                    className={style.controls_remove_button} 
                    // onClick={() => dispatch(removeItems())}
                    onClick={() => setIsOpen(true)}
                    tabIndex='0' 
                    type='button'  
                >
                    Удалить выбранные
                </button>
            }

            {isOpen &&
                <Modal onClose={() => setIsOpen(false)} open={isOpen}>
                    <h2>Удалить товары</h2>
                    <p>Вы точно хотите удалить выбранные товары? Отменить данное действие будет невозможно.</p>
                    <button onClick={() => removeItemsDialog()}>Удалить</button>
                </Modal>
            }
             
        </div>
    )
}

export default CartControls