import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productAPI } from "../../api/api";

export const getProducts = createAsyncThunk(
    "products/getProducts",
    async (params, thunkAPI) => {
        const {hash, offset, limit, fields, filters, sort} = params;
        try {
            const res = await productAPI.search(hash, offset, limit, fields, filters, sort);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);  

export const getSetBestsellers = createAsyncThunk(
    "products/getSetBestsellers",
    async (_, thunkAPI) => {
        try {
            const res = await productAPI.search('set/bestsellers', 0, 5);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);
export const getSetPromo = createAsyncThunk(
    "products/getSetPromo",
    async (_, thunkAPI) => {
        try {
            const res = await productAPI.search('set/new', 0, 20);
            return res;        
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);   
const builderProducts = (set, builder, get, loading) => {
    builder.addCase(get.pending, (state) => {
        state[loading] = true;
    });
    builder.addCase(get.fulfilled, (state, { payload }) => {
        state[set] = payload.products;
        state.count = payload.count;
        if (set !== 'products' && payload.products.length === 0) {
            state[loading] = true;
        } else {
            state[loading] = false;
        }
    });
    builder.addCase(get.rejected, (state) => {
        state[loading] = false;
    });
}
const productsSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        bestsellers: null,
        promo: null,
        // filtered: [],
        // related: [],
        // offset: 0,
        //currentPage: 1,
        // sorting: 'minimum_price',
        // sort: 'name&order=asc',
        count: 0,
        // limit
        pageSize: 28, 
        isLoading: false,
        isLoadingBestsellers: false,
        isLoadingPromo: false
    },
    reducers: {
        // getSort: (state, { payload }) => {
        //     state.sort = payload
        // }
        // setCurrentPage: (state, { payload }) => {
        //     state.currentPage = currentPage
        // }
        // filterByPrice: (state, { payload }) => {
        //   state.filtered = state.list.filter(({ price }) => price < payload);
        // },
        // getRelatedProducts: (state, { payload }) => {
        //   const list = state.list.filter(({ category: { id } }) => id === payload);
        //   state.related = shuffle(list);
        // },
    },
    extraReducers: (builder) => {
        // Products
        builderProducts('products', builder, getProducts, 'isLoading')

        // builder.addCase(getProducts.pending, (state) => {
        //     state.isLoading = true;
        // });
        // builder.addCase(getProducts.fulfilled, (state, { payload }) => {
        //     state.products = payload.products;
        //     // state.bestsellers = payload.products;
        //     // state.pageSize = payload.limit;
        //     state.count = payload.count;
        //     state.isLoading = false;
        // });
        // builder.addCase(getProducts.rejected, (state) => {
        //     state.isLoading = false;
        // });

        // Bestsellers
        builderProducts('bestsellers', builder, getSetBestsellers, 'isLoadingBestsellers')

        // Promo
        builderProducts('promo', builder, getSetPromo, 'isLoadingPromo')
    },
});
  
export default productsSlice.reducer;
// export const {
//     getSort,
// } = productsSlice.actions;