import React, { useEffect } from 'react'
import Modal from '../ModalDialog';
import ProductCart from '../Product/ProductCart';
import { getProduct, getSkus } from '../../features/product/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Feather from "react-icons/fi";

const ProductsPreview = ({ skus, id, setIsOpen, isOpen, ...props }) => {
    const items = props;
    const isLoading = false;

    // const dispatch = useDispatch();
    // const { items, isLoading } = useSelector(({ product }) => product);
    // useEffect(() => {
    //     if (!id) return;
    //     dispatch(getProduct(id));   
    // }, [dispatch, id]);

    // const dispatch = useDispatch();
    // const { skus } = useSelector(({ product }) => product);
    // useEffect(() => {
    //     if (!id) return;
    //     dispatch(getSkus(id));   
    // }, [dispatch, skus]);

    return (
        <Modal onClose={() => setIsOpen(false)} open={isOpen}>    
            {isLoading 
                ? 'Загружаю...' 
                : skus && <>
                    <Feather.FiX className='modal_close' onClick={() => setIsOpen(false)} />
                    <ProductCart {...items} skus={skus} id={id} />
                </>
            } 
        </Modal>
    )
}

export default ProductsPreview