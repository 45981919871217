import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartItem from './CartItem/CartItem'
import CartTotal from './CartTotal'
import { addOrder, getOrderItemsAvailability } from '../../features/cart/cartSlice'
import CustomerAddForm from '../Customer/CustomerAddForm'
import style from './Cart.module.css'
import CartControls from './CartControls'

const Cart = () => {
    const { cart, isCheckedControls } = useSelector(({ cart }) => cart)
    const { contact_id } = useSelector(({ customer }) => customer)
    const dispatch = useDispatch()

    const getItems = () => {
        const items = []
        cart?.map(({id, quantity, checked}, i) => {
            if ( !checked ) return;
            items[i] = {
                sku_id: id,
                quantity: quantity
            }
        })
        return items
    }
    const getTotalQuantity = () => {
        let total = 0
        cart?.forEach(item => {
            total += item.quantity
        })
        return total
    } 
    // Массив для отправки заказа
    const data = { 
        contact: contact_id, 
        comment: 'тестовый заказ',
        items: getItems(),    
    }
    const countChecked = cart?.filter(item => item.checked === true).length;

    // Проверка актуальности корзины
    const ids = cart.map(item => item.id).join(',');
    useEffect(() => {
        if (!ids) return;
        dispatch(getOrderItemsAvailability( ids ))   
    }, []);

    return (
        <div className="container">
            
            <div className='row'>
                <div className='col s12 m8 l8'>
                    <h1 
                        className={style.basket_header} 
                        data-count={getTotalQuantity()}>
                        Корзина   
                    </h1>

                    {cart.length > 0 && 
                        <CartControls 
                            isCheckedControls={isCheckedControls} 
                            countChecked={countChecked} 
                        />
                    }

                    {/* {success.length && 'Заказ успешно оформлен'} */}

                    {cart.length > 0 ? cart?.map((item) => (
                        <CartItem
                            key={item.id}
                            id={item.id}
                            image={item.image_url}
                            title={item.name}
                            price={item.price} 
                            quantity={item.quantity}
                            sku={item.sku}
                            product_id={item.product_id}
                            checked={item.checked}
                            count={item.count} 
                            disabled={item.disabled}
                        />
                        )) : 'Корзина пуста' 
                    }
                </div>
                <div className='col s12 m4 l4'>

                    <CartTotal cart={cart} />

                    {!contact_id 
                        ? <CustomerAddForm />
                        : <button 
                            onClick={() => dispatch(addOrder( data ))}
                            disabled={!cart.length}
                        >Оформить
                        </button>
                    }
                    
                    {/* <button 
                        onClick={() => dispatch(getOrderItemsAvailability( ids ))}
                        disabled={!countChecked}
                    >Проверить актуальность корзины
                    </button> */}
                    
                </div>
            </div>
            
        </div>
    )
}

export default Cart