import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customerAPI } from "../../api/api";

export const addCustomer = createAsyncThunk(
    "customer/addCustomer",
    async (data, thunkAPI) => {
        try {
            const res = await customerAPI.add(data);
            return res;        

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
  );  

  const customerSlice = createSlice({
    name: "customer",
    initialState: {
        contact_id: 0,
        isLoading: false,
    },
    reducers: {
        // filterByPrice: (state, { payload }) => {
        //   state.filtered = state.list.filter(({ price }) => price < payload);
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(addCustomer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addCustomer.fulfilled, (state, { payload }) => {
            state.contact_id = payload.contact_id;
            state.isLoading = false;
        });
        builder.addCase(addCustomer.rejected, (state) => {
            state.isLoading = false;
        });
    },
  });
  
  export default customerSlice.reducer;