import React from 'react'
import style from './CartItem.module.css'
import { incrementQuantity, decrementQuantity, removeItem, checkedItem } from '../../../features/cart/cartSlice'
import { useDispatch } from 'react-redux'
import Price from '../../common/Price/Price'
import { useNavigate } from 'react-router-dom'
import * as Feather from "react-icons/fi";

function CartItem({ id, image, title, price, quantity=0, sku, product_id, checked, disabled, count = null }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (

        <div className={`${style.item} ${disabled && style.item_disabled}`}>
            <label className={style.checkbox}>
                <input type="checkbox" name="" 
                    onChange={() => dispatch(checkedItem(id))} 
                    checked={checked}
                    disabled={disabled} 
                />
            </label>           
            <img 
                className={style.image} 
                src={image ? image : '/images/dummy200.png'} 
                alt='item' 
                onClick={() => navigate(`/product/${product_id}`)} 
            />       
            <div className={style.title}>
                {title} {sku} <br/>
                
                {disabled 
                    ? <span className={style.stock_none}>Только что закончился на складе</span> 
                    : <span className={style.stock_high}>
                        {count === null ? 'В наличие' : `Есть на складе ${parseInt(count)} шт.`}
                    </span>
                }
            </div> 
            <div className={style.price}>
                <Price price={price} />
            </div>
            <div className={style.incrDec}>
                <button disabled={disabled} onClick={() => dispatch(decrementQuantity(id))}>-</button>
                <span>{quantity}</span>
                <button disabled={disabled} onClick={() => dispatch(incrementQuantity(id))}>+</button>
            </div>
            <div
                className={style.removeButton} 
                onClick={() => dispatch(removeItem(id))}>
                <Feather.FiX />
            </div>          
        </div> 
    )
}

export default CartItem