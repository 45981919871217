import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { getFeatures } from '../../features/feature/featureSlice';

const Features = ({ 
        // type_id, 
        product_features 
    }) => {

    // const dispatch = useDispatch();
    // useEffect(() => {
    //     if (!type_id) return;
    //     dispatch(getFeatures(type_id));   
    // }, [dispatch, type_id]);
    const { features, isLoading } = useSelector(({ features }) => features);
    const items_filter = Object.values(features).filter(      
        item => product_features[item.code]
    );

    return isLoading ? (
        <section className="preloader">Loading...</section>
    ) : (
        <div>
            {items_filter.length > 0 && 
                <h2>Характеристики 
                    {/* Type ID {type_id} */}
                </h2>
            }

            {items_filter && items_filter.map((feature, i) =>
                <div key={i}>
                    {feature.type === 'divider' 
                    ?   <p className="divider">
                            {feature.name}
                        </p>
                    : (
                        <p className="line-bottom">
                            <span className='line-bottom-name'>
                                {feature.name}:
                            </span> {' '}
                            {feature.type === 'color' 
                            ? <span className='line-bottom-value'>
                                {product_features[feature.code].map((m, index) => 
                                    <span key={index}>{ (index ? ', ' : '') + m.value }</span>
                                )}
                                </span>
                            : <span className='line-bottom-value'>
                                {product_features[feature.code] instanceof Array 
                                    ? product_features[feature.code].map((m, index) => 
                                        <span key={index}>{ (index ? ', ' : '') + m }</span>
                                    )
                                    : product_features[feature.code]
                                }
                                </span>
                            }
                        </p>  
                    )}
                </div>
            )}
               
            {/* {items_filter && items_filter.map((feature, i) =>
                <div key={i}>

                    <strong>{feature.name}:</strong> {' '}
                    {feature.type === 'color' && 
                        <span>{product_features[feature.code].map((m, index) => 
                            <span key={index}>{ (index ? ', ' : '') + m.value }</span>
                        )}
                        </span>
                    }
                    {feature.type === 'varchar' && 
                        <span>{product_features[feature.code] instanceof Array 
                            ? product_features[feature.code].map((m, index) => 
                                <span key={index}>{ (index ? ', ' : '') + m }</span>
                            )
                            : product_features[feature.code]
                            }
                        </span>
                    }
                    {(feature.type === 'boolean' ||
                        feature.type === 'dimension.weight' ||
                        feature.type === 'text'
                    ) &&
                        <span>{product_features[feature.code]}</span>
                    }
                    
                </div>
            )} */}

                   
        </div>
    )
}

export default Features