import React, { useState } from 'react'
import * as Feather from "react-icons/fi"
import style from './FiltersAside.module.css'
import Filters from '../FiltersMod2';
import AsideMenu from '../../AsideMenu/AsideMenu';

const FiltersAside = ({ isLoadingFeatures, selectedCount, category_id, initialFilter, handleSubmit, handleReset, 
    handleInputChange, /*handleInputRange,*/ price_range }) => {

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar((sidebar) => !sidebar);

    const handleFiltersAsideSubmit = (e) => {     
        handleSubmit(e);
        showSidebar();          
    };

    return <>
        <aside>
            <span className={style.button} onClick={ showSidebar }>
                <Feather.FiFilter /> Фильтры {selectedCount > 0 && 
                <span className={style.count}>{selectedCount}</span>}
            </span>
        </aside>
        
        <AsideMenu onClose={showSidebar} open={sidebar}>
            {/* {sidebar &&  */}
            <form className={style.sidebar_container} onSubmit={handleFiltersAsideSubmit} >
                <div className={style.sidebar_header}>
                    <h3>Фильтры</h3> <Feather.FiX className={style.close} onClick={ showSidebar }/>
                </div>
                <div className={style.sidebar_content}>
                    <Filters
                        isApply={false}
                        isLoadingFeatures={isLoadingFeatures} 
                        initialFilter={initialFilter}
                        handleInputChange={handleInputChange}
                        // handleInputRange={handleInputRange}
                        price_range={price_range}
                        category_id={category_id}
                    />
                </div>
                <div className={style.sidebar_action}>
                    <button type="submit" /*onClick={ showSidebar }*/>Применить</button>
                    <button type="reset" onClick={ handleReset }>Сбросить</button>
                </div>
            </form>
            {/* }  */}
        </AsideMenu>
    </>
}

export default FiltersAside