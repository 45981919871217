import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './components/App/App';
import { Provider } from 'react-redux';
import { store } from './features/store';
import { BrowserRouter } from "react-router-dom";

createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            {/* <StrictMode> */}
                <App />
            {/* </StrictMode>     */}
        </BrowserRouter>
    </Provider>
);