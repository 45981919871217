import React from 'react'

const ProductRelated = ({ cross_selling, upselling }) => {


    return <section className='section'>

        {/* <h3>Сопутствующие товары (Cross-selling)</h3>
            <p>0 - выключено, 1 - из настроект по типу товара, 2 - вручную для данного товара</p>
            <p>related/cross_selling/12 - работает только при значении 2 </p>
            {items.cross_selling}
            <p>Схожие и альтернативные товары (upselling)</p>
            {items.upselling}
            <p>Как вариант искать по search/type_id= id типа товара</p> */}
            <code>Перекрестные продажи (cross-selling) и схожие/альтернативные товары (upselling):
                0 — отключены,
                1 — автоматический подбор по настройкам типа товара,
                2 — список указан вручную, связь по таблице shop_product_related</code>
    </section>
}

export default ProductRelated