// Поиск по url. Вроде одинаково работают
//const category = categories.find(c => c.url == slug);
//const category = categories.find(c => c.url.includes(slug) );

// Поиск по url. Использование стрелочной функции и деструктуризации
// const category = categories.find(({ url }) => url === slug );

// поиск категории по url с вложениями find a nested element by url
export const findNestedElementUrl = (arr, itemId, nestingKey) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.url === itemId) return item;
        if (item[nestingKey]) return findNestedElementUrl(item[nestingKey], itemId, nestingKey)
    }, null)
);

export const findItemNested = (arr, itemId, nestingKey) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
    }, null)
);