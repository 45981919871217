import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import styles from "../../styles/Products.module.css";
import Price from '../common/Price/Price';
import { DOMAIN } from '../../utils/constants';

import { addToCart } from '../../features/cart/cartSlice';
import { getSkus } from '../../features/product/productSlice';

import { useDispatch, useSelector } from 'react-redux';
import * as Feather from "react-icons/fi";
// import dummy from './../../images/dummy200.png'
import ProductsPreview from './ProductsPreview';
import ProductsItemImages from './ProductsItemImages';


const ProductsItem = ({item, cart, toast}) => {
    const { id, sku = '', sku_id, sku_type, images, image_url, image_id, image_count,
        name, price, compare_price, currency, max_price, min_price,
        count, available, status } = item;
       
    const dispatch = useDispatch();
    const [inbasket, setInbasket] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [imageId, setImageId] = useState(image_id);
    const [currentImage, setCurrentImage] = useState(image_url);

    const { skus, isSkusLoading } = useSelector(({ product }) => product);

    useEffect(() => {
        const cartItem = cart?.find((item) => item.id === sku_id)
        if (cartItem?.id === sku_id) {
            setInbasket(true);
        }          
    }, [cart]);

    useEffect(() => {
        if (!imageId) return
        setCurrentImage( new URL(images[imageId]?.url_thumb, DOMAIN) )         
    }, [imageId]);

    const addToCartOnClick = () => {
        if (sku_type === '0') {
            dispatch(addToCart({
                id: sku_id, name, price, image_url, 
                product_id: id, count
            }))
        } else {
          dispatch( getSkus(id) ); 
          setIsOpen(true);   
        }  
        if (toast) toast(`Товар ${name} добавлен в корзину`)
    }
    
    const imageOnLoad = (event) => {
        if (event.currentTarget.className !== "error") {
          event.currentTarget.className = "success";
        }
    };    
    const imageOnError = (event) => {
        event.currentTarget.src = '/images/dummy200.png';
        event.currentTarget.className = "error";
    };

    // форматирум текст заголовка, убираем Caps, &quot;
    function capFirst(str) {
        str = str[0].toUpperCase() + str.slice(1).toLowerCase()
        str = str.replace(/&quot;/g, '"')
        return str
    }
    function discountPercentage(price, compare) {
        const percentage = Math.round((compare - price) / (compare / 100));
        return percentage;
    }
    
    return (
        <>           
            <Link to={`/product/${id}`} className={styles.image}>
            {( image_id && images ) ? <>    
                {image_count > 1 && 
                    <ProductsItemImages 
                        images={images} 
                        defaultImageId={image_id} 
                        imageId={imageId} 
                        setImageId={setImageId} 
                    />
                }
                <img 
                    loading={'lazy'}
                    onLoad={imageOnLoad}
                    onError={imageOnError} 
                    // srcSet={`${image_url} 1x, ${new URL(images[imageId]?.url_thumb, DOMAIN)} 2x`}
                    // src={new URL(images[imageId]?.url_thumb, DOMAIN)}
                    src={currentImage}
                    alt={name} 
                />
            </> : <img 
                    loading={'lazy'}
                    src={'/images/dummy200.png'}
                    alt={name} 
                />
            }
            </Link>
            

            <div className={styles.wrapper}>

                <div className={styles.prices}>
                    {min_price === max_price
                        ?   <div className={styles.price}>
                                <Price price={price} currency={currency} />
                            </div>
                        :   <div className={styles.price}>
                                от <Price price={min_price} currency={currency} />
                                {/* ...<Price price={max_price} currency={currency} /> */}
                            </div>
                    }
                    {compare_price > 0 && <div>
                        <s className={styles.compare_price}>
                            <Price price={compare_price} currency={currency} />
                        </s>
                        <span className={styles.percentage}>
                           −{discountPercentage(price, compare_price)}% 
                        </span>
                        
                    </div>}
                </div>   
 
                <Link className={styles.info} to={`/product/${id}`}>
                    <div className={styles.cat}>
                        {sku_type === '1' && sku}
                        {/* {item.rating_html} */}
                    </div>
                    <h3 className={styles.title}>{capFirst(name)}</h3>                    
                </Link>

                {sku_type === '0' ?        
                <button
                    className={inbasket ? styles.inbasket : styles.add} 
                    type='button' 
                    disabled={available === '0' || status === '0' || parseInt(count) === 0 || inbasket}
                    onClick={() => addToCartOnClick()}
                    >{inbasket 
                        ? <>В корзине <Feather.FiCheck /></> 
                        : <>В корзину <Feather.FiShoppingCart /></> }
                </button>
                : <button
                    className={styles.add} 
                    type='button' 
                    onClick={() => addToCartOnClick()}
                    >Выбрать
                </button>
                }

                {isOpen && 
                    // <ProductsPreview id={id} isOpen={isOpen} setIsOpen={setIsOpen} /> 
                    <ProductsPreview 
                        {...item}
                        images={Object.values(images)} 
                        skus={!isSkusLoading && Object.values(skus)} 
                        isOpen={isOpen} 
                        setIsOpen={setIsOpen}                        
                    />   
                }   
            </div>
        </>
    )
}

export default ProductsItem