import React from 'react'
import style from './../../styles/Breadcrumbs.module.css'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ items = [] }) => {

    return (
        <div className={style.breadcrumbs}>
            {items?.map((breadcrumb, i) => 
                
                ( (items.length !== i + 1) && breadcrumb.url !== null )
                ? 
                    <Link key={i}
                        className={style.breadcrumb}   
                        to={breadcrumb.url}
                    >{breadcrumb.name}
                    </Link>
                : 
                    <span key={i} className={style.breadcrumb}>
                        {breadcrumb.name}
                    </span>
                
            )}
        </div>
    )
}

export default Breadcrumbs